module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-TLHTMBV7MT","head":false,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"876360489948061"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
